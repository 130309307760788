.customField {
  margin-bottom: 25px;

  &:last-child {
    /* margin-bottom: 0; */
  }

  @media (--viewportMedium) {
    /* margin-bottom: 32px; */

    &:last-child {
      /* margin-bottom: 0; */
    }
  }
}
.homeServiceCheckbox{
  margin: 0 0 22px;
  display: block;
  & label{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 !important;
    & span{
      margin: 0;
    }
  }
}
.bookingOrder {
  margin-top: 28px;
  margin-left: 30px;
  margin-right: 30px;

  & .bookingHeader {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    justify-content: space-between;

    & .bookingHeading {
      font-size: 20px;
      font-weight: 500;
      color: #1c2a53;
      line-height: 30px;
    }

    & .moreLink {
      & a {
        display: flex;
        font-size: 14px;
        font-weight: 600;
        color: #555f7e;
        line-height: 22px;
        align-items: center;

        & svg {
          margin-left: 12px;
        }
      }
    }
  }

  & .bookingTable {
    & table {
      width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;
      background-color: transparent;

      & thead {
        border-radius: 8px 8px 0 0;
        background-color: #f8f8f8;

        & th {
          padding: 20px;
          font-size: 14px;
          font-weight: 600;
          color: #8e95a9;
          line-height: 22px;
          text-align: left;
        }
      }

      & tbody {
        & td {
          padding: 20px;
          text-align: left;

          & .heading {
            font-size: 14px;
            font-weight: 600;
            color: #555f7e;
            line-height: 22px;
          }

          & .mainHeading {
            font-size: 14px;
            font-weight: 400;
            color: #555f7e;
            line-height: 22px;
          }

          & .amountDiscountBadge {
            padding: 2px;
            height: 18px;
            color: #fff;
            display: flex;
            font-size: 10px;
            font-weight: 400;
            line-height: 20px;
            border-radius: 40px;
            align-items: center;
            justify-content: center;
            background-color: #bf1b82;
          }

          & .bookedText {
            font-size: 14px;
            font-weight: 400;
            color: #ffa000;
            line-height: 22px;
          }

          & .actionButton {
            gap: 16px;
            display: flex;

            & .actionIcon {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

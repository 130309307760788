.root {
  height: 100%;
  width: 100%;
  border: 0;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
}

.clickedWithMouse {
  &:focus {
    outline: none;
  }
}
.menuButton{
  background-color: unset;
  min-height: auto;
}
/* Default isOpen class - no styles yet */
.isOpen {
}

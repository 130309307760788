.smallButton {
  height: 16px;
  min-height: 16px;
  line-height: 16px;
  border: none;
  background: unset;
  &:disabled {
    background-color: unset;
    opacity: 0.6;
  }
  &:hover,
  &:focus {
    background-color: #007bff58;
  }
}
.cartContent {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #6c7275;
  border-radius: 4px;
  padding: 8px;
}

.countContainer {
  align-self: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #121212;
}
.cartButton {
  width: calc(50% - 9px);
  border: 1px solid #e7e7e8;
  border-radius: 40px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000000;
  cursor: pointer;
  background-color: #F4B31B;
  margin: auto;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover+label .box,
  &:focus+label .box,
  &:checked+label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  &:hover+label .boxSuccess,
  &:focus+label .boxSuccess,
  &:checked+label .boxSuccess {
    stroke: var(--successColor);
  }

  /* Display the "check" when checked */
  &:checked+label .checked {
    display: inline;
    stroke: var(--marketplaceColor);
    strokeWidth: 1px;
  }

  /* Display the "check" when checked */
  &:checked+label .checkedSuccess {
    display: inline;
    stroke: var(--successColor);
    strokeWidth: 1px;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus+label .text,
  &:hover+label .text,
  &:checked+label .text {
    color: #4A4A4A;
  }
}

.label {
  display: none;
  align-items: center;
  padding: 0;

  @media (max-width: 767px) {
    margin-top: -10px;
  }
}

.checkboxWrapper {
  /* This should follow line-height */
  display: none !important;
  height: 32px;
  margin-top: -1px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checked {
  display: none;
  fill: var(--marketplaceColor);
}

.checkedSuccess {
  display: none;
  fill: var(--successColor);
}

.boxSuccess,
.box {
  stroke: var(--matterColorNegative);
}

.text {}

.textRoot {
  color: #4A4A4A;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.3047058880329132px;
  text-transform: capitalize;
  @media (max-width: 767px) {
    font-size: 12px;
    margin-left: 3px;
    min-width: 13px;
  }
}

input[type='checkbox']+label {
  display: flex;
  gap: 4px;
  cursor: pointer;
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox']+label:before {
  content: "";
  /* background-image: url(../../assets/auth-images/check.png); */
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2223%22%20height%3D%2223%22%20viewBox%3D%220%200%2023%2023%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M11.5%200.25C5.30125%200.25%200.25%205.30125%200.25%2011.5C0.25%2017.6987%205.30125%2022.75%2011.5%2022.75C17.6987%2022.75%2022.75%2017.6987%2022.75%2011.5C22.75%205.30125%2017.6987%200.25%2011.5%200.25ZM16.8775%208.9125L10.4987%2015.2913C10.3412%2015.4487%2010.1275%2015.5387%209.9025%2015.5387C9.6775%2015.5387%209.46375%2015.4487%209.30625%2015.2913L6.1225%2012.1075C5.79625%2011.7812%205.79625%2011.2412%206.1225%2010.915C6.44875%2010.5887%206.98875%2010.5887%207.315%2010.915L9.9025%2013.5025L15.685%207.72C16.0113%207.39375%2016.5513%207.39375%2016.8775%207.72C17.2038%208.04625%2017.2038%208.575%2016.8775%208.9125Z%22%20fill%3D%22%23DCE2EF%22%2F%3E%3C%2Fsvg%3E");
  background-position: center;
  /* border: 1.5px solid #00000099; */
  border-radius: 2px;
  display: inline-block;
  width: 22px;
  min-width: 22px;
  height: 24px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  /* background: #2e2e2e00; */

  @media (max-width: 767px) {
    min-width: 26px;
  }
}

input[type='checkbox']+label:active:before {
  transform: scale(0);
}

input[type='checkbox']:checked+label:before {
  background: var(--successColor);
  /* border-color: var(--matterColorNegative); */
  color: white;
  border: none;
  /* background-image: url(../../assets/auth-images/checked.png); */
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2223%22%20height%3D%2223%22%20viewBox%3D%220%200%2023%2023%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M11.5%200.25C5.30125%200.25%200.25%205.30125%200.25%2011.5C0.25%2017.6987%205.30125%2022.75%2011.5%2022.75C17.6987%2022.75%2022.75%2017.6987%2022.75%2011.5C22.75%205.30125%2017.6987%200.25%2011.5%200.25ZM16.8775%208.9125L10.4987%2015.2913C10.3412%2015.4487%2010.1275%2015.5387%209.9025%2015.5387C9.6775%2015.5387%209.46375%2015.4487%209.30625%2015.2913L6.1225%2012.1075C5.79625%2011.7812%205.79625%2011.2412%206.1225%2010.915C6.44875%2010.5887%206.98875%2010.5887%207.315%2010.915L9.9025%2013.5025L15.685%207.72C16.0113%207.39375%2016.5513%207.39375%2016.8775%207.72C17.2038%208.04625%2017.2038%208.575%2016.8775%208.9125Z%22%20fill%3D%22%2310B981%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  /* background-size: 12px; */
  background-position: center;
  background-size: 22px;
}

input[type='checkbox']:disabled+label:before {
  transform: scale(1);
  border-color: var(--matterColorNegative);
}

input[type='checkbox']:checked:disabled+label:before {
  transform: scale(1);
  background-color: #f7c28f;
  border-color: #f7c28f;
}
.merchantBusinessRoot{
  & label{
    margin-top: 0;
  }
  & input[type='checkbox']+label:before {
    background-image: url("../../assets/images/notChecked.png");
    background-size: 22px;
  }
  & input[type='checkbox']:checked+label:before {
    background-image: url("../../assets/images/checked.png");
  }
}
.isTermSquareCheckbox{
  & label{
    margin-top: 0;
  }
  & input[type='checkbox']+label:before {
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='16' height='15' rx='1.5' stroke='%23DCDCDD'/%3E%3C/svg%3E%0A") !important;
  }
  
  & input[type='checkbox']:checked+label:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 26 26'%3E%3Cpath fill='%2310b981' d='M17.8 10.59L12.39 16l-.53.53a.996.996 0 0 1-1.41 0l-.41-.41l-1.83-1.82a.87.87 0 0 1 0-1.23l.02-.01c.33-.34.88-.34 1.22 0l1.7 1.7l5.41-5.41c.34-.33.89-.33 1.22 0l.02.02c.33.34.33.88 0 1.22M19.99 5H6c-.55 0-1 .45-1 1.01V20c0 .55.45 1 1 1h13.99c.56 0 1.01-.45 1.01-1V6.01C21 5.45 20.55 5 19.99 5'/%3E%3C/svg%3E") !important;
    background-size: 22px;
  }
}
.arabicDirection{
  & .textRoot{
    margin-right: 10px !important;
  }
}
.root {
  & input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="date"]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    opacity: 0;
    pointer-events: none;
  }
  input[type="date"]::-moz-calendar-picker-indicator {
    display: none;
  }
  & input[type='date'] {
    appearance: textfield;
    display: flex;
    text-align-last: left;

    @media (max-width: 580px) {
      width: calc(100vw - 48px) !important;
    }
    &:after{
      position: absolute;
      content: "";
      right: 14px;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M19 4h-2V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3m1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7h16Zm0-9H4V7a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h2a1 1 0 0 1 1 1Z'/%3E%3C/svg%3E");
    }
  }
}

.input {
}

.textarea {
}

.inputSuccess {
}

.inputError {
  border: var(--borderErrorField);
}

.inputWrapper {
  position: relative;

  & .showHideButton {
    right: 0;
    top: 50%;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 900;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
.arabicDirection {
  & input[type='date'] {
    text-align-last: right;
    @media (min-width: 1023px) {
      justify-content: flex-end;
    }
    &:after{
      position: absolute;
      content: "";
      left: 14px;
      right: unset;
    }
  }
  & .togglePassword {
    left: 30px !important;
  }
  & .calendarIcon {
    left: 14px ;
    right: unset !important;
  }
}
.togglePassword {
  right: 0;
  top: 50%;
  border: none;
  cursor: pointer;
  font-size: 12px;
  padding-right: 0;
  font-weight: 900;
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: unset;
  min-height: auto;
  width: auto;
  color: #000;
}

.calendarIcon {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #000;
}
